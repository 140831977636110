import $ from 'jquery'
import { on } from 'delegated-events'

const modalScreen = document.querySelector('.modal-screen')

const closeModal = () => {
  modalScreen.classList.remove('opacity-100')
  modalScreen.classList.add('pointer-events-none')
  modalScreen.classList.add('opacity-0')
  document.documentElement.classList.remove('overflow-hidden')
}

const setupModal = () => {
  setTimeout(() => {
    modalScreen.classList.remove('opacity-0')
    modalScreen.classList.remove('pointer-events-none')
    modalScreen.classList.add('opacity-100')
    document.documentElement.classList.add('overflow-hidden')
  }, 500)

  on('click', '.modal-close', closeModal)

  document.addEventListener('click', (e) => {
    if (!e.target.closest('.subscribe-modal')) {
      closeModal()
    }
  })

  on('submit', '.subscribe-form', (e) => {
    e.preventDefault()

    const form = e.target
    const message = form.querySelector('.subscribe-message')
    const submitButton = form.querySelector('[type=submit]')

    submitButton.disabled = true
    message.classList.add('hidden')

    $.ajax({
      type: 'post',
      url: form.action,
      data: $(form).serialize(),
      cache: false,
      dataType: 'jsonp',
      contentType: 'application/json; charset=utf-8',
      success: function(data) {
        if (data.result === 'error') {
          if (data.msg.indexOf('already subscribed') > 0) {
            message.textContent = 'You are already subscribed'
          } else {
            message.textContent = 'There was a problem with your submission'
          }
          message.classList.remove('hidden')
        }

        if (data.result === 'success') {
          message.textContent = 'Thank you for subscribing'
          message.classList.remove('hidden')
          form.reset()
        }
      },
      complete: () => {
        submitButton.disabled = false
      }
    });
  })
}

setupModal()
